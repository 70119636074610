<ng-container *ngIf="(!article.setitem || (article.setitem && article.promotion)) && !article.volume_price?.length">
  <span class="badge badge-secondary text-uppercase" *ngIf="!showroomMode" [ngClass]="[article.promotion ? 'show' : 'd-none', article.promotion_price && article.promotion_price.discount ? 'has-perc' : '']">{{
    (article.promotion_price && article.promotion_price.discount ? 'DISCOUNT_BADGE' : 'PROMOTION') | translate }}<ng-container *ngIf="article.promotion_price && article.promotion_price.discount">{{"DISCOUNT"|translate}} {{numberFormat.removeDecimals(article.promotion_price.discount)}}%</ng-container></span>
</ng-container>
<span class="badge bg-success me-1" *ngIf="article.new">{{ 'NEW' | translate }}</span>
<span class="badge bg-warning" *ngIf="article.setitem">{{ 'SETITEM' | translate }}</span>
<span class="badge bg-info" *ngIf="article.volume_price && article.volume_price.length && !showroomMode">{{ 'VOLUME_PRICES' | translate }}</span>
<span class="badge badge-lease me-1" *ngIf="article.is_lease && article.article_type === 'F'">{{ 'LEASE_AVAILABLE' | translate }}</span>
<span class="badge badge-default availableForDs" ngbTooltip="{{'AVAILABLE_FOR_DROPSHIPMENT'|translate}}"
  placement="bottom" *ngIf="article.dropshipment && showroomMode">
  <small><i class="material-icons">home</i></small>
</span>
<span class="badge badge-battery rounded-pill text-bg-light " *ngIf="article.mandatory_battery || article.has_mandatory_item"><u>{{ 'MANDATORY_BATTERY' | translate }}</u></span>
